<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <Toast
        v-if="toastData.show"
        :type="toastData.type"
        :message="toastData.message"
        :show="toastData.show"
        :time="10000"
        @clearToast="clearToast"
      />
      <main class="page-content">
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
            <li>Digital Poem</li>
            <li
              v-if="activeFrom === 1"
              :class="activeFrom === 1 ? 'ProximaNovaBold' : ''"
            >
              DataSource
            </li>
            <li v-else>
              <span @click="activeFrom = 1">DataSource</span>
            </li>
            <!--<li
              :class="activeFrom === 2 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 2"
            >
              Input Data Summary
            </li>
            <li v-else-if="activeFrom === 3">
              <span @click="activeFrom = 2">Input Data Summary</span>
            </li>-->
            <li
              :class="activeFrom === 2 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 2"
            >
              KPI
            </li>
            <li v-else-if="activeFrom === 3">
              <span @click="activeFrom = 2">KPI</span>
            </li>
            <li
              :class="activeFrom === 3 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 3"
            >
              Data Sufficiency
            </li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 28px">
          <div
            class="col-sm-12 col-xs-12 col-md-12 col-lg-12 d-flex justify-center"
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <w-multi-step-bar
                :stepName="stepName"
                :activeState="activeFrom"
                @changeStep="[getAnalyticsChannels()]"
              ></w-multi-step-bar>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 12px"
          v-if="activeFrom === 1"
        >
          <!--<w-tab
            :tabName="activeTab"
            :tabs="categorizedSelectedConnectors"
            @activeTab="changeTab($event)"
          >
          </w-tab>-->
          <CreateNewConnection />
        </div>
        <div class="w-100 px-3 mt-4" v-if="activeFrom === 1">
          <!--<div
            class="container-fluid tab-panel"
            style="margin-top: 36px; padding-left: 12px"
            v-for="(tab, index) in categorizedSelectedConnectors"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >-->
          <div
            class="card w-100"
            v-for="(tabContent, index) in singlePageArray"
            :key="index"
          >
            <div
              class="card-header font-large ProximaNovaBold"
              style="
                padding: 0.75rem 1.5rem;
                background: transparent;
                border: 0px !important;
              "
            >
              {{ tabContent.title }}
            </div>
            <div class="card-body">
              <div>
                <w-connector
                  :addActiveClass="true"
                  :connectors="tabContent.connectors"
                  :title="tabContent.title"
                  :selectedConnectorsList="selectedConnectors"
                >
                </w-connector>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div
          v-if="categorizedSelectedConnectors.length === 0 && activeFrom === 1"
          class="no-items-wrapper"
        >
          <div class="text-center">
            <img src="@/assets/tech-life-media2.png" alt="" />
            <div class="no-items-description">
              Oops!<br />
              Sorry to say, it seems like no connection has been connected yet
            </div>
          </div>
        </div>
        <div class="w-100 py-4" v-if="activeFrom === 2">
          <div
            class="container-fluid"
            style="margin-top: 36px; padding-left: 12px"
          >
            <div class="col-md-12 col-lg-12">
              <div class="card w-100">
                <div class="row">
                  <div class="col-md-4 colo-lg-4">
                    <div class="w-100 content-center h-100">
                      <img src="@/assets/Icons/kpi.webp" alt="KPI" />
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-8">
                    <div class="w-100 row">
                      <div
                        class="w-100 d-flex my-5"
                        style="
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <span class="ProximaNovaBold" style="font-size: x-large"
                          >Define KPI</span
                        >
                        <div
                          style="
                            display: grid;
                            align-items: center;
                            text-align: center;
                            font-size: small;
                            color: #ff5a5a;
                          "
                        >
                          <span
                            v-for="(error, index) in showError"
                            :key="index"
                            class="d-none"
                            >{{ error }} *</span
                          >
                          <span>{{ modalErrorContent }}</span>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-md-6 colo-lg-6 d-flex justify-center">
                          <div class="w-100">
                            <w-dropdown
                              :options="clientDropdown"
                              :placeHolder="'Select'"
                              :labelText="'Client'"
                              class="mb-5"
                              :selectedOption="selectedClient"
                              @input="getSelectedClient($event)"
                            ></w-dropdown>
                          </div>
                        </div>

                        <div
                          class="col-md-6 col-lg-6 d-flex justify-center p-0"
                        >
                          <div class="w-100 row p-0 col-md-12 col-lg-12 mb-5">
                            <div
                              class="p-0 d-flex multidropdownLabel"
                              style="
                                align-items: center;
                                font-size: large;
                                width: 40%;
                              "
                            >
                              <span class="pl-3 w-100">KPI Source</span>
                              <div class="line"></div>
                            </div>

                            <div class="p-0" style="width: 60%">
                              <Treeselect
                                :placeHolder="'Select'"
                                :rootOptions="resourceDropdown"
                                class="resource"
                                :selectedValue="selectedResource"
                                @selectedField="getSelectedResource($event)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 colo-lg-6 d-flex justify-center">
                          <div class="w-100">
                            <w-dropdown
                              :options="countryDropdown"
                              class="mb-5"
                              :labelText="'Country'"
                              :selectedOption="selectedCountry"
                              @input="selectCountry($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <div
                          class="col-md-6 colo-lg-6 d-flex justify-center p-0"
                        >
                          <div class="w-100">
                            <w-dropdown
                              :options="roiMeasurmentDropdown"
                              class="mb-5"
                              :labelText="'Range For ROI'"
                              @input="selectROiMeasurment($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <div class="col-md-6 colo-lg-6 d-flex justify-center">
                          <div class="w-100">
                            <w-dropdown
                              :options="kpiDropdownOption"
                              class="mb-5"
                              :labelText="'KPI'"
                              @input="selectkpiMarket($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <div
                          class="col-md-6 colo-lg-6 d-flex justify-center p-0"
                        >
                          <div
                            class="w-100 p-0 d-flex"
                            style="height: 60px !important"
                          >
                            <div
                              class="p-0 d-flex multidropdownLabel"
                              style="
                                align-items: center;
                                font-size: large;
                                width: 40%;
                              "
                            >
                              <span class="pl-3 w-100">Input Start Date</span>
                              <div class="line"></div>
                            </div>
                            <DatePicker
                              type="month"
                              v-model="customDate"
                              style="
                                width: 60% !important;
                                height: 60px !important;
                              "
                              ref="datePicker"
                              format="MMM YYYY"
                              :disabled-date="disabledBeforeTodayAndAfterAWeek"
                            />
                          </div>
                        </div>

                        <!--<div class="col-md-12 colo-lg-12 d-flex">
                          <div class="row w-100 m-0">
                            <div
                              class="w-100 p-0 mb-5"
                              v-for="(obj, index) in kpiDropdownOption"
                              :key="index"
                            >
                              <div
                                class="w-100 ProximaNovaRegular mb-3"
                                style="font-size: large"
                              >
                                <b>{{ obj.market }}</b>
                              </div>
                              <div class="w-100 p-0 mb-4">
                                <div
                                  class="col-md-4 col-lg-4 p-0"
                                  v-for="(kpi, i) in obj.kpis"
                                  :key="i"
                                >
                                  <div
                                    class="col-12 col-sm-12 col-md-12 mt-3 pl-2"
                                  >
                                    <div
                                      class="w-100"
                                      style="float: right;align-items: center; display: flex;"
                                    >
                                      <input
                                        type="checkbox"
                                        class="form-check-input m-0"
                                        :id="kpi.value + obj.market"
                                        :checked="kpi.checked"
                                        v-model="kpi.checked"
                                      />
                                      <label
                                        :for="kpi.value + obj.market"
                                        class="form-check-label m-0 pl-3 ProximaNovaRegular"
                                        style="position: relative; font-size: medium; cursor: pointer;box-shadow: none; color: #727988"
                                        >{{ kpi.name }}</label
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
                      </div>
                      <div class="w-100 pr-5">
                        <div class="row mr-2 pr-2 mb-4" style="float: right">
                          <w-button
                            :buttonLoader="loaderButton"
                            :buttonText="'Create Plan'"
                            :class="{ resetPasswordLink: isDisable }"
                            :isDisable="isDisable"
                            @buttonClicked="createPlan"
                          >
                          </w-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="container-fluid px-5"
            style="margin-top: 36px; padding-left: 12px"
          >
            <w-table
              :tableProperties="tableDataValues"
              :tableLoading="tableLoding"
              :showHeading="true"
              :headingText="'Model History'"
              :customRowColor="true"
              @retryModel="restartModel($event)"
              :noDataText="'No Model found'"
              @viewModelResult="viewModelResult($event)"
            >
            </w-table>
          </div>
          <div class="w-100 d-flex justify-content-end px-3">
            <w-pagination
              :currentPage="currentPage"
              :perPage="rowsPrePage"
              :totalRows="totalRows"
              @getSelectedPageEvent="getSelectedPageEvent($event)"
            >
            </w-pagination>
          </div>
        </div>
        <input-data-summary v-if="activeFrom === 4"></input-data-summary>
        <data-sufficent
          v-if="activeFrom === 3"
          @startModal="createPlan"
          :kpiSource="selectedResource"
          :startDate="startDate"
          :buttonLoader="loaderButton"
          @updateError="errorUpdate"
          :kpiCountry="this.selectedCountry.id"
        ></data-sufficent>
        <div class="container-fluid w-100" v-if="activeFrom < 2">
          <div class="row pt-5 pb-5 mr-2" style="float: right">
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              @buttonClicked="nextToSource"
              :class="{ resetPasswordLink: checkConnection }"
              :isDisable="checkConnection"
            >
            </w-button>
          </div>
        </div>
        <!--<div class="w-100 d-flex justify-center align-center">
            <Learning />
        </div>-->
        <status-modal
          ref="solutionModal"
          @progressDone="doneProgress"
          @statusError="modalError($event)"
        />
      </main>
    </div>
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import MultiStepProgressBar from "@/components/Solutions/MultiStepProgressBar.vue";
import Button from "@/components/Profile/Button.vue";
// import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
// import Learning from "@/components/AIMO-AI/Learning.vue"
import StatusModal from "@/components/Modal/StatusModal.vue";
import { DataConnection } from "@/services/DataConnectionService.js";
import Treeselect from "@/widgets/MultiSelectDropdown.vue";
import InputDataSummary from "./InputDataSummary.vue";
import { AIDigitalPoem } from "../../services/AIDigitalPoemServices";
import DataTable from "@/widgets/DataTable.vue";
import Pagination from "@/widgets/Pagination.vue";
import DataSufficent from "./DataSufficent.vue";
import CreateNewConnection from "@/widgets/CreateNewConnection.vue";
import "@/mixins/SortArrayByArray";
import DatePicker from "vue2-datepicker";
import Toast from "@/components/Toast/Toast.vue";
import moment from "moment";
const aiDigitalPoem = new AIDigitalPoem();
const dataConnection = new DataConnection();
var resourceTimer;
var tableInterval;
export default {
  props: [],
  components: {
    "w-connector": Connector,
    DatePicker,
    Toast,
    "data-sufficent": DataSufficent,
    "w-pagination": Pagination,
    "w-multi-step-bar": MultiStepProgressBar,
    "w-button": Button,
    // "w-tab": Tab,
    "w-dropdown": Dropdown,
    "status-modal": StatusModal,
    "input-data-summary": InputDataSummary,
    // Learning
    Treeselect,
    CreateNewConnection,
    "w-table": DataTable,
  },
  data() {
    return {
      startDate: null,
      tableLoding: false,
      customDate: null,
      currentPage: 1,
      rowsPrePage: 5,
      totalRows: 0,
      singlePageArray: [],
      tableDataValues: {
        fields: [
          {
            key: "kpi.targetCol",
            label: "KPI",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "kpi.analyticsType",
            label: "Resource",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "kpi.country",
            label: "Country",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "kpi.startDate",
            label: "Start Date",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "ROIRange",
            label: "ROI Range",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "dateFormatter",
            label: "Created On",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "AiStatuspreProcess",
            label: "Pre-Process",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "AiStatusAttribution",
            label: "Attribution",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "AiStatusOptimization",
            label: "Optimization",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "actionRow",
            label: "Action",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      digitalMediaList: [
        "Google Ads",
        "Facebook Ads",
        "LinkedIn Ads",
        "Twitter Ads",
        "Display and Video 360",
        "Micosoft Ads",
        "Pinterest",
        "SnapchatF",
      ],
      analyticsList: [
        "Adobe Analytics",
        "Google Trends",
        "Google_Analytics",
        "Adobe Analytics (Service)",
        "Adobe Analytics(WSSE)",
        "Convert",
        "Etracker",
        "Google Analytics(Oauth)",
        "Google Analytics(Service)",
        "IBM Analytics",
        "Magnite Performance",
        "Mixpanel",
        "Optimizely",
        "Piwik Pro",
        "Similar Web",
        "Site Impact",
        "Webtrekk",
        "Webtrends",
      ],
      activeConnectorList: [],
      clientDropdown: [],
      selectedClient: {},
      roiMeasurmentDropdown: [
        { text: "3 Months", id: "3" },
        { text: "6 Months", id: "6" },
        { text: "One Year", id: "12" },
      ],
      validationApi: 0,
      modalErrorContent: "",
      countryDropdown: [],
      loaderButton: "normal",
      selectedCountry: {},
      selectedRoiMesaurment: {},
      selectedKpiMarket: {},
      resourceDropdown: [],
      selectedResource: [],
      // kpiDropdownOption: [
      //   {
      //     market: "Select Your KPI",
      //     kpis: [
      //       { name: "Goals", value: "Goals", checked: false },
      //       { name: "Revenue", value: "Revenue", checked: false },
      //       { name: "Transactions", value: "Transactions", checked: false },
      //       { name: "MQL", value: "MQL", checked: false },
      //       { name: "SQL", value: "SQL", checked: false },
      //     ],
      //   },
      // ],
      kpiDropdownOption: [
        { text: "Revenue", id: "transactionRevenue" },
        { text: "Transactions", id: "transactions" },
        // { text: "Sessions", id: "Sessions" },
        // { text: "Conversions(Incl Goals)", id: "Conversions" },
      ],
      selectedKPI: "",
      activeTab: "",
      activeFrom: 1,
      orderSubCategory: [],
      selectedConnectors: [],
      iconList: [
        { channel: "Google Ads", icon: "google" },
        { channel: "Facebook Ads", icon: "facebook" },
        { channel: "Instagram", icon: "instagram" },
        { channel: "LinkedIn Ads", icon: "linkedin" },
        { channel: "Twitter Ads", icon: "twitter" },
        { channel: "DV 360", icon: "dv360" },
        { channel: "Microsoft Ads", icon: "microsoft" },
        { channel: "Pinterest", icon: "Pinterest" },
        { channel: "Snapchat", icon: "Snapchat" },
        { channel: "Google Analytics", icon: "google-anal" },
        { channel: "Facebook Insights", icon: "facebook" },
        { channel: "Instagram Insights", icon: "instagram" },
        { channel: "YouTube Insights", icon: "youtube" },
        { channel: "LinkedIn Insights", icon: "linkedin" },
        { channel: "Twitter Insights", icon: "twitter" },
        { channel: "Google Ads Keyword Planner", icon: "google" },
        { channel: "Google_Sheets", icon: "Google_Sheets" },
        { channel: "Google Trends", icon: "Google Trends" },
      ],
      categorizedSelectedConnectors: [],
      stepName: [
        "DataSource",
        // "Input Data Summary",
        "KPI",
        "Data Sufficiency",
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      categorys: ["", "Analytics", "trends"],
      validationList: [],
      uniqueId: "",
      selectedPageNo: 1,
    };
  },

  beforeDestroy() {
    clearTimeout(tableInterval);
  },
  beforeCreate() {
    clearTimeout(tableInterval);
  },
  computed: {
    getIcon() {
      return (data) => {
        var icon = this.iconList.find((obj) => obj.channel === data);
        if (icon !== undefined) {
          return icon.icon;
        } else {
          return data;
        }
      };
    },
    validateConnection() {
      var data = [];
      data.push({
        name: "Digital Media",
        value: this.digitalMediaList.filter((obj) =>
          this.activeConnectorList.includes(obj)
        ),
      });
      data.push({
        name: "Web Analtics",
        value: this.analyticsList.filter((obj) =>
          this.activeConnectorList.includes(obj)
        ),
      });
      return data;
    },
    showError() {
      var connectionError = [];
      for (var i = 0; i < this.validateConnection.length; i++) {
        if (this.validateConnection[i].value.length === 0) {
          connectionError.push(
            "We don't have " + this.validateConnection[i].name + " data"
          );
        }
      }
      var data = this.validationList.filter((obj) => {
        return obj !== true;
      });
      if (connectionError.length !== 0) {
        return connectionError;
      } else if (data.length !== 0) {
        return data;
      }
      return [];
    },
    checkConnection() {
      if (this.categorizedSelectedConnectors.length !== 0) {
        return false;
      }
      return true;
    },
    isDisable() {
      // var kpis = [];
      // for (var i = 0; i < this.kpiDropdownOption.length; i++) {
      //   for (var j = 0; j < this.kpiDropdownOption[i].kpis.length; j++) {
      //     if (this.kpiDropdownOption[i].kpis[j].checked) {
      //       kpis.push({
      //         // market: this.kpiDropdownOption[i].market,
      //         kpi: this.kpiDropdownOption[i].kpis[j].value,
      //       });
      //     }
      //   }
      // }
      if (
        this.selectedResource.length !== 0 &&
        this.checkObj(this.selectedRoiMesaurment) !== 0 &&
        // kpis.length !== 0 &&
        this.checkObj(this.selectedCountry) !== 0 &&
        this.checkObj(this.selectedKpiMarket) !== 0 &&
        this.loaderButton === "normal" &&
        this.customDate !== null &&
        this.checkObj(this.selectedRoiMesaurment) !== 0
        // this.showError.length === 0 &&
        // this.validationApi>= 3
      ) {
        return false;
      }
      return true;
    },
    checkObj() {
      return (data) => {
        return Object.keys(data).length;
      };
    },
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1)
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    errorUpdate() {
      this.toastData = {
        show: true,
        type: "failed",
        message: "Something went wrong Please try after sometime",
      };
    },
    restartModel(e) {
      if (e.preProcess.status === "error") {
        aiDigitalPoem.startPreProcess(e._id).then((res) => {
          console.log(res);
        });
      } else if (e.attribution.status === "error") {
        aiDigitalPoem.startAttribution(e._id).then((res) => {
          console.log(res);
        });
      } else if (e.optimization.status === "error") {
        aiDigitalPoem.startOptimization(e._id).then((res) => {
          console.log(res);
        });
      }
      var self = this;
      setTimeout(function () {
        self.getTableData();
      }, 5000);
    },
    viewModelResult(e) {
      var market =
        e.kpi.targetCol.charAt(0).toUpperCase() + e.kpi.targetCol.slice(1);
      sessionStorage.setItem("kpiConfig", market);
      sessionStorage.setItem("runId", e._id);
      // if (e.kpi.roiRange === undefined) {
        sessionStorage.setItem("roiRange", "0");
      // } else {
        // sessionStorage.setItem("roiRange", e.kpi.roiRange);
      // }
      this.$router.push("/solutions/digital-poem/model-results");
    },
    getSelectedPageEvent(event) {
      if (event != this.selectedValidationDigitalPageNo) {
        this.selectedPageNo = event;
        this.currentPage = event;
        this.getTableData();
      }
    },
    createPlan() {
      if (this.activeFrom === 2) {
        this.activeFrom = 3;
        return;
      }
      if (this.activeFrom !== 3 || this.isDisable) {
        return;
      }
      // var kpis = [];
      // for (var i = 0; i < this.kpiDropdownOption.length; i++) {
      //   var data = [];
      //   for (var j = 0; j < this.kpiDropdownOption[i].kpis.length; j++) {
      //     if (this.kpiDropdownOption[i].kpis[j].checked) {
      //       data.push(this.kpiDropdownOption[i].kpis[j].value);
      //     }
      //   }
      //   if (data.length !== 0) {
      //     kpis.push({ value: data });
      //   }
      // }
      // console.log(kpis);
      this.loaderButton = "loader";
      this.modalErrorContent = "";
      var data = {
        country: this.selectedCountry.id,
        market: "All",
        kpi: this.selectedKpiMarket.id,
        clientId: this.selectedClient.id,
        // clientId: "ktyxratbkfxigjugcvll",
        kpiResource: JSON.stringify(this.selectedResource),
        startDate: this.startDate,
        roiRange: this.selectedRoiMesaurment.id,
      };
      aiDigitalPoem
        .postKpiData(data)
        .then((res) => {
          this.uniqueId = res.runId;
          this.loaderButton = "normal";
          sessionStorage.setItem("runId", res.runId);
          sessionStorage.setItem("roiRange", this.selectedRoiMesaurment.id);
          this.startModal();
          // this.$router.push("/solutions/digital-poem/model-results");
          this.$refs.solutionModal.open(this.uniqueId);
        })
        .catch(() => {
          this.loaderButton = "normal";
          this.uniqueId = "";
        });
    },
    startModal() {
      aiDigitalPoem.startPreProcess({ runId: this.uniqueId }).then((res) => {
        console.log(res);
      });
    },
    getTableData() {
      clearTimeout(tableInterval);
      this.tableLoding = false;
      aiDigitalPoem
        .getAllModelsResult(
          // "zbtkqrqkajmrvukdqbkc",
          this.selectedClient.id,
          this.rowsPrePage,
          this.currentPage
        )
        .then((res) => {
          this.tableDataValues.items = [];
          this.tableDataValues.items = res.history;
          this.totalRows = res.totalRows;
          this.tableLoding = false;
          var count = 0;
          for (var i = 0; i < res.history.length; i++) {
            if (
              res.history[i].preProcess.status === "started" ||
              res.history[i].attribution.status === "started" ||
              res.history[i].optimization.status === "started"
            ) {
              count++;
            }
          }
          if (count !== 0) {
            var self = this;
            tableInterval = setTimeout(function () {
              self.getTableData();
              clearTimeout(tableInterval);
            }, 10 * 1000);
          }
        })
        .catch(() => {
          this.tableLoding = false;
        });
    },
    doneProgress() {
      this.$router.push("/solutions/digital-poem/model-results");
    },
    getSelectedClient(e) {
      this.selectedClient = e;
    },
    getSelectedResource(e) {
      this.selectedResource = e;

      clearTimeout(resourceTimer);
      var self = this;
      resourceTimer = setTimeout(function () {
        self.getCountry();
        clearTimeout(resourceTimer);
      }, 2000);
    },
    modalError(e) {
      this.modalErrorContent = e;
    },
    getCountry() {
      this.countryDropdown = [];
      this.selectedCountry = {};
      if (this.selectedResource.length === 0) {
        return;
      }
      dataConnection
        .getCountry(
          this.selectedClient.id,
          // sessionStorage.getItem("subId"),
          "get_country",
          "",
          this.selectedResource
        )
        .then((res) => {
          this.countryDropdown = [];
          this.selectedCountry = {};
          this.countryDropdown.push({ id: "all", text: "All" });
          this.selectedCountry = { id: "all", text: "All" };
          for (var i = 0; i < res.length; i++) {
            this.countryDropdown.push({ id: res[i], text: res[i] });
          }
        })
        .catch(() => {
          this.errorUpdate();
        });
    },
    selectCountry(e) {
      this.selectedCountry = e;
    },
    selectROiMeasurment(e) {
      this.selectedRoiMesaurment = e;
    },
    selectkpiMarket(e) {
      sessionStorage.setItem("kpiConfig", e.text);
      this.selectedKpiMarket = e;
    },
    nextToSource() {
      this.getAnalyticsChannels();
      if (this.activeFrom === 1) {
        this.activeFrom = 2;
        return;
      }

      this.activeFrom = 3;
      this.getAnalyticsChannels();
    },
    getAnalyticsChannels() {
      this.resourceDropdown = [];
      dataConnection
        .getCountry(
          this.selectedClient.id,
          // sessionStorage.getItem("subId"),
          "get_channel",
          "",
          ""
        )
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.resourceDropdown.push({ id: res[i], label: res[i] });
          }
        })
        .catch(() => {
          this.errorUpdate();
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getConnectedChannelList() {
      var AnalyticsList = [
        "Google_Analytics",
        "Adobe Analytics",
        "Google Trends",
      ];
      var SocialList = [
        "LinkedIn Insights",
        "Instagram Insights",
        "Facebook Insights",
        "YouTube Insights",
        "Twitter Insights",
        "Google Ads Keyword Planner",
      ];
      var kpiList = [
        "Google_Analytics",
        "Adobe Analytics (Service)",
        "Adobe Analytics",
        "Adobe Analytics(WSSE)",
        "Convert",
        "Etracker",
        "Google Analytics(Oauth)",
        "Google Analytics(Service)",
        "IBM Analytics",
        "Magnite Performance",
        "Mixpanel",
        "Optimizely",
        "Piwik Pro",
        "Similar Web",
        "Site Impact",
        "Webtrekk",
        "Webtrends",
        "Active Campaign",
        "Braze",
        "Campaign Monitor",
        "Cordial",
        "Emarsys",
        "Evalanche",
        "Hubspot Destination",
        "Hubspot",
        "Iterable",
        "Klaviyo",
        "Mailchimp",
        "Mailing Work",
        "Mailworx",
        "Marketo",
        "Microsoft Dynamics",
        "Piano",
        "Salesforce Marketing Cloud",
        "Salesforce",
        "Sendinblue",
        "Sharpspring",
        "Teamleader",
        "Zendesk Sell",
        "Shopify",
      ];
      dataConnection
        .getConnectedChannels(sessionStorage.getItem("subId"))
        .then((res) => {
          // for (var i = 0; i < res.response.length; i++) {}
          this.activeConnectorList = res.response;
          this.categorizedSelectedConnectors = [];
          this.activeTab = "";
          var sortOreder = [
            "Digital Media",
            "Analytics",
            "Social Insights",
            "KPI",
          ];
          for (var i = 0; i < res.response.length; i++) {
            if (kpiList.includes(res.response[i])) {
              var findCategory = this.categorizedSelectedConnectors.find(
                (obj) => obj.category === "KPI"
              );
              if (findCategory === undefined) {
                this.categorizedSelectedConnectors.push({
                  category: "KPI",
                  tabContent: [
                    {
                      title: "KPI",
                      connectors: [
                        {
                          name: res.response[i],
                          image: this.getIcon(res.response[i]),
                        },
                      ],
                    },
                  ],
                });
              } else {
                this.categorizedSelectedConnectors
                  .find((obj) => obj.category === "KPI")
                  .tabContent[0].connectors.push({
                    name: res.response[i],
                    image: this.getIcon(res.response[i]),
                  });
              }
            }
            if (AnalyticsList.includes(res.response[i])) {
              findCategory = this.categorizedSelectedConnectors.find(
                (obj) => obj.category === "Analytics"
              );
              if (findCategory === undefined) {
                this.categorizedSelectedConnectors.push({
                  category: "Analytics",
                  tabContent: [
                    {
                      title: "Analytics",
                      connectors: [
                        {
                          name: res.response[i],
                          image: this.getIcon(res.response[i]),
                        },
                      ],
                    },
                  ],
                });
              } else {
                this.categorizedSelectedConnectors
                  .find((obj) => obj.category === "Analytics")
                  .tabContent[0].connectors.push({
                    name: res.response[i],
                    image: this.getIcon(res.response[i]),
                  });
              }
            } else if (SocialList.includes(res.response[i])) {
              findCategory = this.categorizedSelectedConnectors.find(
                (obj) => obj.category === "Social Insights"
              );
              if (findCategory === undefined) {
                this.categorizedSelectedConnectors.push({
                  category: "Social Insights",
                  tabContent: [
                    {
                      title: "Social Insights",
                      connectors: [
                        {
                          name: res.response[i],
                          image: this.getIcon(res.response[i]),
                        },
                      ],
                    },
                  ],
                });
              } else {
                this.categorizedSelectedConnectors
                  .find((obj) => obj.category === "Social Insights")
                  .tabContent[0].connectors.push({
                    name: res.response[i],
                    image: this.getIcon(res.response[i]),
                  });
              }
            } else {
              findCategory = this.categorizedSelectedConnectors.find(
                (obj) => obj.category === "Digital Media"
              );
              if (findCategory === undefined) {
                this.categorizedSelectedConnectors.push({
                  category: "Digital Media",
                  tabContent: [
                    {
                      title: "Digital Media",
                      connectors: [
                        {
                          name: res.response[i],
                          image: this.getIcon(res.response[i]),
                        },
                      ],
                    },
                  ],
                });
              } else {
                this.categorizedSelectedConnectors
                  .find((obj) => obj.category === "Digital Media")
                  .tabContent[0].connectors.push({
                    name: res.response[i],
                    image: this.getIcon(res.response[i]),
                  });
              }
            }
            if (i === res.response.length - 1) {
              this.categorizedSelectedConnectors = this.sortArray(
                this.categorizedSelectedConnectors,
                sortOreder,
                "category"
              );
              this.reArrangeList();
            }
            this.activeTab = this.categorizedSelectedConnectors[0].category;
          }
        });
    },

    reArrangeList() {
      this.singlePageArray = [];
      for (var i = 0; i < this.categorizedSelectedConnectors.length; i++) {
        for (
          var j = 0;
          j < this.categorizedSelectedConnectors[i].tabContent.length;
          j++
        ) {
          this.singlePageArray.push(
            this.categorizedSelectedConnectors[i].tabContent[j]
          );
        }
      }
    },
    validateUserData() {
      this.validationList = [];
      this.validationApi = 0;
      for (var i = 0; i < this.categorys.length; i++) {
        dataConnection
          .validateData(this.selectedClient.id, this.categorys[i])
          .then((res) => {
            ++this.validationApi;
            this.validationList.push(res);
          });
      }
    },
  },
  created() {
    this.clientDropdown = [];
    this.clientDropdown.push({
      id: sessionStorage.getItem("subId"),
      // id:"kempuphnyhunxpdenfmq",
      text: sessionStorage.getItem("organization"),
    });
    this.selectedClient = this.clientDropdown[0];
    this.getConnectedChannelList();
    this.loaderButton = "normal";
    this.validateUserData();
    this.getTableData();
  },
  watch: {
    customDate(newVal) {
      if (newVal) {
        this.startDate = moment(newVal).format("YYYY-MM");
      }
    },
  },
};
</script>
<style>
.resource .vue-treeselect__control {
  height: 55px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
<style scoped>
.multidropdownLabel {
  background: #eff5ff;
  color: #313846;
}
.form-check-input {
  position: unset !important;
}
.no-items-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
</style>
