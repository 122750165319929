<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid p-0 bottom_header">
          <div class="card w-100 mt-1">
            <div class="row d-felx pl-5 pr-0 col-12 py-4 ">
              <div
                class="col-md-2 col-lg-2 d-flex p-0 d-flex "
                style="align-items: center; font-family:ProximaNovaBold;font-size: medium;justify-content:space-between;"
              >
                <h2>Business At Glance</h2>
              </div>
              <div
                class="col-md-10 col-lg-10 d-flex"
                style="align-items: center; "
              >
                <div class="col-md-10 col-lg-10">
                  <div class="col-md-4 col-lg-4">
                    <div class="w-100 row p-0 col-md-12 col-lg-12 ">
                      <div
                        class="col-md-4 col-lg-4 p-0 d-flex multidropdownLabel"
                        style="align-items: center;font-size: medium;"
                      >
                        <span class="pl-3 w-100">Year</span>
                        <div data-v-315de6ea="" class="line"></div>
                      </div>
                      <div class="col-md-8 col-lg-8 p-0">
                        <w-multi-drop-down
                          :placeHolder="'Select'"
                          :rootOptions="yearList"
                          class="resource"
                          :selectedValue="selectedYear"
                          @selectedField="getSelectedYear($event)"
                        ></w-multi-drop-down>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4">
                    <div class="w-100 row p-0 col-md-12 col-lg-12 ">
                      <div
                        class="col-md-4 col-lg-4 p-0 d-flex multidropdownLabel"
                        style="align-items: center;font-size: medium;"
                      >
                        <span class="pl-3 w-100">Quarter</span>
                        <div data-v-315de6ea="" class="line"></div>
                      </div>
                      <div class="col-md-8 col-lg-8 p-0">
                        <w-multi-drop-down
                          :placeHolder="'Select'"
                          :rootOptions="getQuarter"
                          class="resource"
                          :selectedValue="selectedQuarter"
                          @selectedField="getSelectedQuarter($event)"
                        ></w-multi-drop-down>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4">
                    <div class="w-100 row p-0 col-md-12 col-lg-12 ">
                      <div
                        class="col-md-4 col-lg-4 p-0 d-flex multidropdownLabel"
                        style="align-items: center;font-size: medium;"
                      >
                        <span class="pl-3 w-100">Months</span>
                        <div data-v-315de6ea="" class="line"></div>
                      </div>
                      <div class="col-md-8 col-lg-8 p-0">
                        <w-multi-drop-down
                          :placeHolder="'Select'"
                          :rootOptions="getMonth"
                          class="resource"
                          :selectedValue="selectedMonth"
                          @selectedField="getSelectedMonth($event)"
                        ></w-multi-drop-down>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-lg-2 p-0" style="font-size: medium;">
                  <div>
                    <input
                      type="checkbox"
                      class="form-check-input m-0 mr-2"
                      id="exampleCheck1"
                      :checked="showValidationTable"
                      v-model="showValidationTable"
                    />
                    <label for="exampleCheck1" class="m-0 mr-2"
                      >Show Validation</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center p-0
            "
          >
            <div class="row col-12">
              <div class="col-md-6 col-lg-6 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Paid and Non Paid</h5>
                    <div class="">
                      <w-single-dropdown
                        :options="paidNonPaidList"
                        :value="selectedPaidNonPaid"
                        @selectedOptions="getSelectedPaidNonPaid($event)"
                      />
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-center">
                    <pie-chart
                      :series="paidNonPaidChartData"
                      :legendDetails="pieLegend"
                      :annotation="''"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Adtype</h5>
                    <div class="">
                      <w-single-dropdown
                        :options="adtypeList"
                        :value="selectedAdtype"
                        @selectedOptions="getSelectedAdtype($event)"
                      />
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-center">
                    <pie-chart
                      :series="adTypeChartData"
                      :annotation="''"
                      :legendDetails="pieLegend"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 my-4  ">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Channel Wise Breakdown</h5>
                    <div class="">
                      <w-single-dropdown
                        :options="adtypeList"
                        :value="selectedChannelWise"
                        @selectedOptions="getSelectedChannelWise($event)"
                      />
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-center">
                    <pie-chart
                      :series="channelWiseChartData"
                      :legendDetails="pieLegend"
                      :annotation="''"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">
                      Channel Level Digital Media Data
                    </h5>
                    <div class="">
                      <w-single-dropdown
                        :options="adtypeList"
                        :value="selectedChannelWiseCost"
                        @selectedOptions="getSelectedChannelWiseCost($event)"
                      />
                    </div>
                  </div>
                  <line-chart
                    yAxisTitle="Millions"
                    :boxshadow="true"
                    :chartData="channelCostChartData"
                  />
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Web Analytics Data</h5>
                    <div class="">
                      <w-single-dropdown
                        :options="webAnalyticsList"
                        :value="selectedWebAnalytics"
                        @selectedOptions="getSelectedWebAnalytics($event)"
                      />
                    </div>
                  </div>
                  <line-chart
                    yAxisTitle="Millions"
                    :boxshadow="true"
                    :chartData="salesChartData"
                  />
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">
                      Total Digital Media Data (All channels)
                    </h5>
                    <div class="">
                      <w-single-dropdown
                        :options="digitalMediaList"
                        :value="selectedDigitalMedia"
                        @selectedOptions="getSelectedDigitalMedia($event)"
                      />
                    </div>
                  </div>
                  <line-chart
                    yAxisTitle="Millions"
                    :boxshadow="true"
                    :chartData="spendChartData"
                  />
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">
                      Classic view of Digital Media data
                    </h5>
                  </div>
                  <w-table
                    :tableLoading="classicTableLoading"
                    :tableProperties="tableDataValuesClassic"
                  >
                  </w-table>
                  <div class="w-100 d-flex justify-content-end px-3">
                    <w-pagination
                      :currentPage="currentPage"
                      :perPage="rowsPrePage"
                      :totalRows="totalRows"
                      @getSelectedPageEvent="
                        getSelectedPageEventClassic($event)
                      "
                    >
                    </w-pagination>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-sm-12 my-4">
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Classic view of Analytics data</h5>
                  </div>
                  <w-table
                    :tableLoading="tableLodingAnalytics"
                    :tableProperties="tableDataValuesAnalytics"
                  >
                  </w-table>
                  <div class="w-100 d-flex justify-content-end px-3">
                    <w-pagination
                      :currentPage="currentPageAnalytics"
                      :perPage="rowsPrePageAnalytics"
                      :totalRows="totalRowsAnalytics"
                      @getSelectedPageEvent="
                        getSelectedPageEventAnalytics($event)
                      "
                    >
                    </w-pagination>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 col-lg-12 col-sm-12 my-4"
                :style="
                  showValidationTable ? 'display: block' : 'display: none'
                "
              >
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Analytics Data Validation</h5>
                    <div class="d-flex" style="align-items: center;">
                      <div style="font-size: medium">
                        <input
                          type="checkbox"
                          class="form-check-input m-0 mr-2"
                          id="showDigitalValid"
                          :checked="showAnalyticsZero"
                          v-model="showAnalyticsZero"
                          @click="showZeroAnalyticsData($event)"
                        />
                        <label for="showDigitalValid" class="m-0 mr-4"
                          >Show All</label
                        >
                      </div>
                      <w-single-dropdown
                        :options="analyticsValidationList"
                        :value="selectedanAlyticsValidation"
                        @selectedOptions="
                          getSelectedAnalyticsValidation($event)
                        "
                      />
                    </div>
                  </div>
                  <w-table
                    :tableProperties="tableDataValuesAnalyticsValidation"
                    :tableLoading="tableLodingAnalyticsValidation"
                  >
                  </w-table>
                  <div class="w-100 d-flex justify-content-end px-3">
                    <w-pagination
                      :currentPage="currentPageValidationAnalytics"
                      :perPage="rowsPrePageValidationAnalytics"
                      :totalRows="totalRowsValidationAnalytics"
                      @getSelectedPageEvent="
                        getSelectedPageEventAnalyticsValidation($event)
                      "
                    >
                    </w-pagination>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 col-lg-12 col-sm-12 my-4"
                :style="
                  showValidationTable ? 'display: block' : 'display: none'
                "
              >
                <div class="card pb-5 pb-lg-0 h-100">
                  <div
                    class="d-flex mx-3 mt-3"
                    style="justify-content: space-between;align-items: center;"
                  >
                    <h5 class="card-heading">Digital Media Data Validation</h5>

                    <div class="d-flex" style="align-items: center;">
                      <div style="font-size: medium">
                        <input
                          type="checkbox"
                          class="form-check-input m-0 mr-2"
                          id="showDigitalValid"
                          :checked="showDigitalZero"
                          v-model="showDigitalZero"
                          @click="showDigitalZeroData($event)"
                        />
                        <label for="showDigitalValid" class="m-0 mr-4"
                          >Show All</label
                        >
                      </div>
                      <w-single-dropdown
                        :options="digitalValidationList"
                        :value="selectedanDigitalValidation"
                        @selectedOptions="getSelectedDigitalValidation($event)"
                      />
                    </div>
                  </div>
                  <w-table
                    :tableProperties="tableDataValuesDigitalValidation"
                    :tableLoading="tableLodingDigitalValidation"
                  >
                  </w-table>
                  <div class="w-100 d-flex justify-content-end px-3">
                    <w-pagination
                      :currentPage="currentPageValidationDigital"
                      :perPage="rowsPrePageValidationDigital"
                      :totalRows="totalRowsValidationDigital"
                      @getSelectedPageEvent="
                        getSelectedPageEventDigitalValidation($event)
                      "
                    >
                    </w-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import MultiSelectDropdown from "@/widgets/MultiSelectDropdown.vue";
import SingleDropdownCheckbox from "@/widgets/SingleDropdownCheckbox.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import LineChart from "@/components/Chart/LineChart.vue";
import DataTable from "@/widgets/DataTable.vue";
import Pagination from "@/widgets/Pagination.vue";
import { BusinessAtGlance } from "@/services/BusinessAtGlanceServices.js";
const businessAtGlance = new BusinessAtGlance();
var timer;
export default {
  props: [],
  components: {
    "w-multi-drop-down": MultiSelectDropdown,
    "w-single-dropdown": SingleDropdownCheckbox,
    PieChart,
    LineChart,
    "w-table": DataTable,
    "w-pagination": Pagination,
  },
  data() {
    return {
      pieLegend: {
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
      },
      classicTableLoading: false,
      selectedQuarter: [],
      selectedMonth: [],
      showValidationTable: false,
      showDigitalZero: false,
      showAnalyticsZero: false,
      tableDataValuesClassic: {
        fields: [
          {
            key: "channelName",
            label: "Platform",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "monthName",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "impressions",
            label: "Impression",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "clicks",
            label: "Click",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "video_views",
            label: "VideoView",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "conversions",
            label: "Conversions",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "cost",
            label: "Cost",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableDataValuesAnalytics: {
        fields: [
          {
            key: "channelName",
            label: "Platform",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "monthName",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "sourceMedium",
            label: "Channel Grouping",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "sessions",
            label: "Sessions",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "transactions",
            label: "Transaction",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "revenue",
            label: "Revenue",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableDataValuesAnalyticsValidation: {
        fields: [
          {
            key: "channel",
            label: "Platform",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "duration",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "esValue",
            label: "ES Dashboard Value",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "mongoValue",
            label: "Mongo Dashboard Value",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "difference",
            label: "Difference",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableDataValuesDigitalValidation: {
        fields: [
          {
            key: "channel",
            label: "Platform",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "duration",
            label: "Duration",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "esValue",
            label: "ES Dashboard Value",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "mongoValue",
            label: "Mongo Dashboard Value",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "difference",
            label: "Difference",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableLodingAnalyticsValidation: false,
      tableLodingDigitalValidation: false,
      tableLodingAnalytics: false,
      spendChartData: {
        colors: ["#050505"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
            
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 5,
            },
            type: "spline",
            name: "Spend",
          },
        ],
        title: {
          text: "",
          align: "right",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {
          align: "center",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "Spends",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "October",
            "November",
            "December",
          ],
        },
      },
      salesChartData: {
        colors: ["#050505"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [
          {
            data: [3, 2, 1, 2, 1, 4, 1, 2, 3, 3, 9, 10],
            marker: {
              symbol: "circle",
              radius: 5,
            },
            type: "spline",
            name: "Sales",
          },
        ],
        title: {
          text: "",
          align: "right",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {
          align: "center",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "Sales",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sept",
            "October",
            "November",
            "December",
          ],
        },
      },
      channelCostChartData: {
        colors: [
          "#050505",
          "#85edff",
          "#b5a9ff",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
        series: [],
        title: {
          text: "",
          align: "right",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {
          align: "center",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "Cost",
            style: {
              color: "#8394b4",
            },
            y: 12,
          },
        },
        xAxis: {
          categories: [],
        },
      },
      yearList: [],
      selectedYear: [],
      adTypeChartData: [
        {
          name: "Value",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      paidNonPaidChartData: [
        {
          name: "Value",
          colorByPoint: true,

          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      channelWiseChartData: [
        {
          name: "Value",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      pieChartColor: ["#ff4081", "#64ffda"],
      options: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Engagements", value: "Engagements" },
        { name: "Video View", value: "Video_view" },
        { name: "Conversions", value: "Conversions" },
        { name: "Conv Value", value: "Conv_value" },
      ],
      paidNonPaidList: [
        { name: "Sessions", value: "sessions" },
        { name: "Transactions", value: "transactions" },
        { name: "Revenue", value: "transactionRevenue" },
      ],
      adtypeList: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Cost", value: "Cost" },
        { name: "Conversions", value: "Conversions" },
      ],
      webAnalyticsList: [
        { name: "Sessions", value: "sessions" },
        { name: "Transactions", value: "transactions" },
        { name: "Revenue", value: "transactionRevenue" },
      ],
      digitalMediaList: [
        { name: "Impressions", value: "Impressions" },
        { name: "Clicks", value: "Clicks" },
        { name: "Cost", value: "Cost" },
        { name: "Conversions", value: "Conversions" },
      ],
      selectedWebAnalytics: { name: "Transactions", value: "transactions" },
      selectedDigitalMedia: { name: "Conversions", value: "Conversions" },
      analyticsValidationList: [
        { name: "Sessions", value: "sessions" },
        { name: "Transactions", value: "transactions" },
      ],
      digitalValidationList: [
        { name: "Cost", value: "Cost" },
        { name: "Impressions", value: "impressions" },
      ],
      selectedanDigitalValidation: {
        name: "Impressions",
        value: "impressions",
      },
      selectedanAlyticsValidation: {
        name: "Transactions",
        value: "transactions",
      },
      selectedChannelWiseCost: { name: "Cost", value: "Cost" },
      selectedAdtype: { name: "Impressions", value: "Impressions" },
      selectedChannelWise: { name: "Impressions", value: "Impressions" },
      selectedPaidNonPaid: { name: "Transactions", value: "transactions" },
      value: { name: "Clicks", value: "Clicks" },
      domLoadedKEY: "",
      currentPage: 1,
      rowsPrePage: 10,
      totalRows: 0,
      totalRowsAnalytics: 0,
      rowsPrePageAnalytics: 10,
      currentPageAnalytics: 1,
      selectedAnalyticsPageNo: "",
      selectedPageNo: "",
      totalRowsValidationAnalytics: 0,
      rowsPrePageValidationAnalytics: 10,
      currentPageValidationAnalytics: 1,
      selectedValidationAnalyticsPageNo: "",
      currentPageValidationDigital: 1,
      rowsPrePageValidationDigital: 10,
      totalRowsValidationDigital: 0,
      selectedValidationDigitalPageNo: "",
    };
  },
  computed: {
    domLoader() {
      if (this.domLoadedKEY === "complete") {
        return true;
      }
      return true;
    },
    getMonth() {
      if (this.selectedQuarter.length === 0 && this.selectedYear.length !== 0) {
        return [
          { id: "JAN", label: "January" },
          { id: "FEB", label: "February" },
          { id: "MAR", label: "March" },
          { id: "APR", label: "April" },
          { id: "MAY", label: "May" },
          { id: "JUN", label: "June" },
          { id: "JUL", label: "July" },
          { id: "AUG", label: "August" },
          { id: "SEP", label: "September" },
          { id: "OCT", label: "October" },
          { id: "NOV", label: "November" },
          { id: "DEC", label: "December" },
        ];
      }
      return [];
    },
    getQuarter() {
      if (this.selectedMonth.length === 0 && this.selectedYear.length !== 0) {
        return [
          { id: "Q1", label: "Quarter-1" },
          { id: "Q2", label: "Quarter-2" },
          { id: "Q3", label: "Quarter-3" },
          { id: "Q4", label: "Quarter-4" },
        ];
      }
      return [];
    },
  },
  methods: {
    selectedOptions(e) {
      this.value = e;
    },
    domloaded() {
      var self = this;
      var domCheck;
      if (document.readyState === "complete") {
        clearInterval(domCheck);
        this.domLoadedKEY = document.readyState;
      } else {
        this.domLoadedKEY = document.readyState;
        domCheck = setInterval(function() {
          self.domloaded();
        }, 1000);
      }
    },
    getAllYear() {
      this.yearList = [];
      businessAtGlance.getYearsForBusiness().then((res) => {
        if (res.response === "No Data Found") {
          return;
        }
        for (var i = 0; i < res.response.length; i++) {
          this.yearList.push({ id: res.response[i], label: res.response[i] });
          if (i === res.response.length - 1) {
            this.selectedYear = [this.yearList[0].id];
            // this.callAllApi();
          }
        }
      });
    },
    getSelectedYear(e) {
      this.selectedYear = e;
      if (e.length === 0) {
        clearTimeout(timer);
        return;
      }
      var self = this;
      clearTimeout(timer);
      timer = setTimeout(function() {
        self.callAllApi();
      }, 2000);
    },
    getSelectedQuarter(e) {
      this.selectedQuarter = e;
      var self = this;
      clearTimeout(timer);
      timer = setTimeout(function() {
        self.callAllApi();
      }, 2000);
    },
    getSelectedMonth(e) {
      this.selectedMonth = e;
      var self = this;
      clearTimeout(timer);
      timer = setTimeout(function() {
        self.callAllApi();
      }, 2000);
    },
    getSelectedPaidNonPaid(e) {
      this.selectedPaidNonPaid = e;
      this.getPaidNonPaidChartData();
    },
    getSelectedAdtype(e) {
      this.selectedAdtype = e;
      this.getAdtypeChartData();
    },
    getSelectedChannelWise(e) {
      this.selectedChannelWise = e;
      this.getChannelWise();
    },
    getSelectedChannelWiseCost(e) {
      this.selectedChannelWiseCost = e;
      this.getChannelWiseCost();
    },
    getSelectedWebAnalytics(e) {
      this.selectedWebAnalytics = e;
      this.getSalesTrendData();
    },
    getSelectedDigitalMedia(e) {
      this.selectedDigitalMedia = e;
      this.getSpendTrendData();
    },
    getPaidNonPaidChartData() {
      var data = {
        name: "rule-mapper",
        orgNumber: sessionStorage.getItem("subId"),
        type: "analytics",
        years: this.selectedYear,
        months: this.selectedMonth,
        quarters: this.selectedQuarter,
        metrics: this.selectedPaidNonPaid.value,
      };
      businessAtGlance.getAdTypeData(data).then((res) => {
        this.paidNonPaidChartData[0].data = [];
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.paidNonPaidChartData[0].data.push({
              name: res[i].rule,
              y: res[i].value,
              sliced: true,
              selected: true,
            });
          } else {
            this.paidNonPaidChartData[0].data.push({
              name: res[i].rule,
              y: res[i].value,
            });
          }
        }
      });
    },
    getAdtypeChartData() {
      var data = {
        name: "adtype-level",
        orgNumber: sessionStorage.getItem("subId"),
        years: this.selectedYear,
        months: this.selectedMonth,
        quarters: this.selectedQuarter,
        type: "digital",
        metrics: this.selectedAdtype.value,
      };
      businessAtGlance.getAdTypeData(data).then((res) => {
        this.adTypeChartData[0].data = [];
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.adTypeChartData[0].data.push({
              name: res[i]["Ad Type"],
              y: res[i].value,
              sliced: true,
              selected: true,
            });
          } else {
            this.adTypeChartData[0].data.push({
              name: res[i]["Ad Type"],
              y: res[i].value,
            });
          }
        }
      });
    },
    getChannelWise() {
      var data = {
        name: "channel-cost-pie",
        orgNumber: sessionStorage.getItem("subId"),
        years: this.selectedYear,
        months: this.selectedMonth,
        quarters: this.selectedQuarter,
        type: "digital",
        metrics: this.selectedChannelWise.value,
      };
      businessAtGlance.getAdTypeData(data).then((res) => {
        this.channelWiseChartData[0].data = [];
        for (var i = 0; i < res.length; i++) {
          if (i == 0) {
            this.channelWiseChartData[0].data.push({
              name: res[i]["Channel"],
              y: res[i].value,
              sliced: true,
              selected: true,
            });
          } else {
            this.channelWiseChartData[0].data.push({
              name: res[i]["Channel"],
              y: res[i].value,
            });
          }
        }
      });
    },
    getSelectedPageEventClassic(event) {
      if (event != this.selectedPageNo) {
        this.selectedPageNo = event;
        this.currentPage = event;
        this.getMediaTableData();
      }
    },
    getMediaTableData() {
      this.classicTableLoading = true;
      var data = {
        name: "classic-media",
        orgNumber: sessionStorage.getItem("subId"),
        years: this.selectedYear,
        months: this.selectedMonth,
        quarters: this.selectedQuarter,
        type: "digital",
        metrics: [
          "clicks",
          "conversions",
          "cost",
          "impressions",
          "video_views",
        ],
        page: this.currentPage,
        size: this.rowsPrePage,
      };
      businessAtGlance
        .getTableDataAnalytics(data)
        .then((res) => {
          this.tableDataValuesClassic.items = [];
          this.classicTableLoading = false;
          this.tableDataValuesClassic.items = res.response.content;
          this.totalRows = res.response.totalElements;
        })
        .catch(() => {
          this.classicTableLoading = false;
        });
    },
    getSelectedPageEventAnalytics(event) {
      if (event != this.selectedAnalyticsPageNo) {
        this.selectedAnalyticsPageNo = event;
        this.currentPageAnalytics = event;
        this.getAnalyticsTableData();
      }
    },
    getSelectedPageEventAnalyticsValidation(event) {
      if (event != this.selectedValidationAnalyticsPageNo) {
        this.selectedValidationAnalyticsPageNo = event;
        this.currentPageValidationAnalytics = event;
        this.getAnalyticsValidationTableData();
      }
    },
    getSelectedPageEventDigitalValidation(event) {
      if (event != this.selectedValidationDigitalPageNo) {
        this.selectedValidationDigitalPageNo = event;
        this.currentPageValidationDigital = event;
        this.getDigitalValidationTableData();
      }
    },
    getAnalyticsTableData() {
      this.tableLodingAnalytics = true;
      var data = {
        name: "classic-analytics",
        orgNumber: sessionStorage.getItem("subId"),
        years: this.selectedYear,
        months: this.selectedMonth,
        quarters: this.selectedQuarter,
        type: "analytics",
        metrics: [
          "Platform",
          "Source",
          "Medium",
          "Channel",
          "Sessions",
          "Transactions",
          "Revenue",
        ],
        page: this.currentPageAnalytics,
        size: this.rowsPrePageAnalytics,
      };
      businessAtGlance
        .getTableDataAnalytics(data)
        .then((res) => {
          this.tableLodingAnalytics = false;
          this.tableDataValuesAnalytics.items = [];
          this.tableDataValuesAnalytics.items = res.response.content;
          this.totalRowsAnalytics = res.response.totalElements;
        })
        .catch(() => {
          this.tableLodingAnalytics = false;
        });
    },
    getSelectedAnalyticsValidation(e) {
      this.selectedanAlyticsValidation = e;
      this.getAnalyticsValidationTableData();
    },
    getSelectedDigitalValidation(e) {
      this.selectedanDigitalValidation = e;
      this.getDigitalValidationTableData();
    },
    getAnalyticsValidationTableData() {
      this.tableLodingAnalyticsValidation = true;
      businessAtGlance
        .getValidationTable(
          sessionStorage.getItem("subId"),
          this.selectedYear,
          this.selectedMonth,
          this.selectedQuarter,
          "analytics",
          this.selectedanAlyticsValidation.value,
          this.currentPageValidationAnalytics,
          this.rowsPrePageValidationAnalytics,
          this.showAnalyticsZero
        )
        .then((res) => {
          this.tableDataValuesAnalyticsValidation.items = [];
          this.tableDataValuesAnalyticsValidation.items = res.response.content;
          this.totalRowsValidationAnalytics = res.response.totalElements;
          this.tableLodingAnalyticsValidation = false;
        })
        .catch(() => {
          this.tableLodingAnalyticsValidation = false;
        });
    },
    getDigitalValidationTableData() {
      this.tableLodingDigitalValidation = true;
      businessAtGlance
        .getValidationTable(
          sessionStorage.getItem("subId"),
          this.selectedYear,
          this.selectedMonth,
          this.selectedQuarter,
          "digital",
          this.selectedanDigitalValidation.value,
          this.currentPageValidationDigital,
          this.rowsPrePageValidationDigital,
          this.showDigitalZero
        )
        .then((res) => {
          this.tableDataValuesDigitalValidation.items = [];
          this.tableDataValuesDigitalValidation.items = res.response.content;
          this.totalRowsValidationDigital = res.response.totalElements;
          this.tableLodingDigitalValidation = false;
        })
        .catch(() => {
          this.tableLodingDigitalValidation = false;
        });
    },
    getSpendTrendData() {
      businessAtGlance
        .getTrendData(
          "spend-trends",
          sessionStorage.getItem("subId"),
          this.selectedYear,
          this.selectedMonth,
          this.selectedQuarter,
          "digital",
          this.selectedDigitalMedia.value
        )
        .then((res) => {
          this.spendChartData.series[0].data = [];
          this.spendChartData.xAxis.categories = [];
          for (var i = 0; i < res.response.length; i++) {
            this.spendChartData.series[0].data.push(res.response[i].value);
            this.spendChartData.xAxis.categories.push(res.response[i].name);
          }
        });
    },
    getSalesTrendData() {
      businessAtGlance
        .getTrendData(
          "sales-trends",
          sessionStorage.getItem("subId"),
          this.selectedYear,
          this.selectedMonth,
          this.selectedQuarter,
          "analytics",
          this.selectedWebAnalytics.value
        )
        .then((res) => {
          this.salesChartData.series[0].data = [];
          this.salesChartData.xAxis.categories = [];
          for (var i = 0; i < res.response.length; i++) {
            this.salesChartData.series[0].data.push(res.response[i].value);
            this.salesChartData.xAxis.categories.push(res.response[i].name);
          }
        });
    },
    getChannelWiseCost() {
      businessAtGlance
        .getChannelCostAllocation(
          "channel-cost",
          sessionStorage.getItem("subId"),
          this.selectedYear,
          this.selectedMonth,
          this.selectedQuarter,
          "digital",
          this.selectedChannelWiseCost.value
        )
        .then((res) => {
          this.channelCostChartData.series = [];
          this.channelCostChartData.xAxis.categories = [];
          var categories = [];
          for (var i = 0; i < res.response.length; i++) {
            var data = [];
            for (var j = 0; j < res.response[i].monthwiseResponse.length; j++) {
              data.push(res.response[i].monthwiseResponse[j].value);
              if (i === 0) {
                categories.push(res.response[i].monthwiseResponse[j].name);
              }
            }
            this.channelCostChartData.series.push({
              data: data,
              marker: {
                symbol: "circle",
                radius: 5,
              },
              type: "spline",
              name: res.response[i].channelName,
            });
            if (i === res.response.length - 1) {
              this.channelCostChartData.xAxis.categories = categories;
            }
          }
        });
    },
    showDigitalZeroData(event) {
      this.currentPageValidationDigital = 1;
      this.totalRowsValidationDigital = 0;
      this.showDigitalZero = event.target.checked
      this.getDigitalValidationTableData();
    },
    showZeroAnalyticsData(event) {
      this.currentPageValidationAnalytics = 1;
      this.totalRowsValidationAnalytics = 0;
      this.showAnalyticsZero = event.target.checked
      this.getAnalyticsValidationTableData();
    },
    callAllApi() {
      this.getPaidNonPaidChartData();
      this.getAdtypeChartData();
      this.getChannelWise();
      this.getMediaTableData();
      this.getAnalyticsTableData();
      this.getSpendTrendData();
      this.getSalesTrendData();
      this.getChannelWiseCost();
      this.getAnalyticsValidationTableData();
      this.getDigitalValidationTableData();
    },
  },
  created() {
    this.domloaded();
    this.getAllYear();
  },
};
</script>
<style scoped>
.form-check-input {
  position: relative;
  top: 0px;
  box-shadow: none;
}
.form-check-input:focus {
  box-shadow: none;
}
.dropdown-wrapper {
  width: 180px;
}
.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}
.multidropdownLabel {
  background: #eff5ff;
  color: #313846;
}
.resource .vue-treeselect__control {
  height: 55px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.card-heading {
  margin: 20px 0px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.bottom_header {
  position: sticky;
  top: 70px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 5px 5px 0px;
}
.card {
  box-shadow: 0 0px 8px 0 #c9ced5;
  background-color: #fff;
}
</style>
